export const projectData = [
  {
    id: 5,
    Image: `projects/pin.jpg`,
    Company: `Pintereach`,
    Stack: [`React /`, `Redux /`, `Node.js /`, `Express`],
    Description: `Article tracking web application to keep developers and scientist organized`,
    URL: `https://elegant-blackwell-a93727.netlify.com/`,
  },
  {
    id: 4,
    Image: `projects/dev.jpg`,
    Company: `DevDesk Queue`,
    Stack: [`React /`, `Redux /`, `Node.js /`, `Express`],
    Description: `Ticketing system created for Lambda School students to request help in areas of coding or general school inquiries. `,
    URL: `https://suspicious-yalow-77c7a7.netlify.com/`,
  },
  {
    id: 3,
    Image: `projects/yasm.jpg`,
    Company: `Y.A.S.M North America`,
    Stack: [`UX /`, `WordPress /`, `Responsive`],
    Description: `Y.A.S.M NA needed a multi-purpose site where they could reach out to individuals and provide a location to accept payments for events they host while engaging the community.`,
    URL: `http://rccgnayasm.org/`,
  },
  {
    id: 2,
    Image: `projects/rccgcor.jpg`,
    Company: `R.C.C.G CoR`,
    Stack: [`UX /`, `WordPress /`, `Responsive`],
    Description: `A website created for a church organization to publish content, accept payments, and update members on program changes.`,
    URL: `https://rccgcor.org/`,
  },
  {
    id: 1,
    Image: `projects/sj.jpg`,
    Company: `Smith & Jones Architects`,
    Stack: [`HTML /`, `JavaScript /`, `Sass`],
    Description: `UI Project for an Architectural firm. Design utilized flexbox for the layout and Javascript for site functionality and interactivity. Animation was done with GSAP`,
    URL: `https://practical-galileo-f93c2e.netlify.com/index.html`,
  },
]

export const aboutData = {
  introP:
    "My name is Oluwaseun Olorunwunmi also known as Sean. I have 5 years' experience in the customer service industry with a passion for technology.",
  secondP:
    "I've always believed if you can code you can create anything! This belief is why I'm at Lambda School, training on Full Stack Web Development. I aspire to create quality applications focused on excellent customer usability.",
  skillsP: `My skills include React, Redux, Node.js, Express, JavaScript, MySQL, SQL, HTML, CSS, and Sass.`,
  skillsP2: `I am open to learning new technologies and look forward to the challenge!`,
}
